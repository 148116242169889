type EndpointDefaults = import('./types').EndpointDefaults

export const DEFAULTS: EndpointDefaults = {
  method: `GET`,
  baseUrl: `https://api.shopup.com.bd`,
  headers: {
    accept: `application/json`,
    'user-agent': `@shopuptech/api-client/${process.env.API_CLIENT_VERSION}`,
  },
}
