import isPlainObject from 'is-plain-object'
import nodeFetch from 'node-fetch'
import { HTTPError } from '../error'
import { getResponseBody } from './utils/get-response-body'

type Endpoint = import('./types').Endpoint
type Response<T> = import('./types').Response<T>

export async function fetchWrapper(
  requestOptions: ReturnType<Endpoint>
): Promise<Response<any>> {
  const { method, url, headers, body, request } = requestOptions

  const options = Object.assign({ method, body, headers }, request)

  const fetch = request!.fetch || nodeFetch

  const extractBody =
    (requestOptions.response && requestOptions.response.body) || getResponseBody

  const ResponseHeaders: Response<any>['headers'] = {}

  try {
    const response = await fetch(url, options)

    const ResponseMeta: Record<string, any> = {
      status: response.status,
      url: response.url,
      body: null,
    }

    for (const [field, value] of response.headers) {
      ResponseHeaders[field] = value
    }

    const body: any = await extractBody(response)
    ResponseMeta.body = body

    const contentIsJSON = isPlainObject(body)

    if (response.status >= 400 || body.error) {
      throw new HTTPError(
        body.error.message || response.statusText,
        response.status,
        {
          error: contentIsJSON ? body.error : body,
          headers: ResponseHeaders,
          request: requestOptions,
        },
        body
      )
    }

    return {
      data: contentIsJSON ? body.data : body,
      headers: ResponseHeaders,
      params: Object.assign({}, contentIsJSON ? body.params : {}),
      meta: Object.assign({}, contentIsJSON ? body.meta : {}, ResponseMeta),
    }
  } catch (error) {
    if (error instanceof HTTPError) {
      throw error
    }

    throw new HTTPError((error as Error).message, 500, {
      error: error,
      headers: ResponseHeaders,
      request: requestOptions,
    })
  }
}
